import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
// @ts-ignore
import TextInfoContent from '@mui-treasury/components/content/textInfo';
// @ts-ignore
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
// @ts-ignore
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { CardActionArea, Chip } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 500,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    // [breakpoints.up('md')]: {
    //   flexDirection: 'row',
    //   paddingTop: spacing(2),
    // },
  },
  media: {
    width: '88%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    // [breakpoints.up('md')]: {
    //   width: '100%',
    //   marginLeft: spacing(-3),
    //   marginTop: 0,
    //   transform: 'translateX(-8px)',
    // },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(147deg, #a6f2ff 0%, #3848fd 74%)',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: spacing(0.5),
    },
  },
}));

interface BlogCardProps {
    title: string;
    date: string;
    body: string;
    imageUrl: string;
    labels: string[];
    link?: string;
}

export default function BlogCard(props: BlogCardProps) {
  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  console.log(contentStyles);
  const shadowStyles = useOverShadowStyles();
  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        className={styles.media}
        image={props.imageUrl}
      />
      <CardContent>
        <TextInfoContent
          classes={contentStyles}
          overline={props.date}
          heading={props.title}
          body={props.body}
        />
        { props.link ? 
            <Button className={buttonStyles} target="_blank" href={props.link}>View on Github</Button>
            :
            <Button className={buttonStyles} disabled>Available on request</Button>
        }
        <br></br>
        <div className={styles.chips}>
        { props.labels.map(
            (label,index) => <Chip key={index} variant="outlined" size="small" label={label} />
        )
        }
        </div>
      </CardContent>
    </Card>
  );
};